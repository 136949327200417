<template>
  <div class="zone">
    <div class="indent">
      <div class="left">基本信息</div>
    </div>

    <div class="conronone">
      <div class="particulars">
        <div class="particularsleft">
          <div class="particularsleftone">订单编号：</div>
          <div class="particularslefttwo">{{ detailData.order_code }}</div>
        </div>
        <div class="particularscentre">
          <div class="particularsleftone">经销商：</div>
          <div class="particularscentretwo">{{ detailData.customer_name }}</div>
        </div>
        <div class="particularsright">
          <div class="particularsleftone">创建时间：</div>
          <div class="particularsrighttwo">{{ detailData.order_date }}</div>
        </div>
      </div>
    </div>

    <div class="indent">
      <div class="left">商品信息</div>
    </div>
    <div class="conron">
      <div class="sheet">
        <el-table :data="tableDataDity" stripe style="width: 100% height:100%">
          <el-table-column label="商品图片" align="center">
            <!-- <template slot-scope="scope">
              <image :url="scope.row.image" width="80" height="80" />
            </template> -->
            <template slot-scope="{ row }">
              <img :src="row.image" alt="" width="60" height="60" />
            </template>
          </el-table-column>
          <el-table-column
            prop="batch"
            label="试剂批号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="inventory_name"
            label="商品名称"
            height="30"
            align="center"
          ></el-table-column>

          <el-table-column
            prop="standard"
            label="规格"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="orders_num"
            label="数量"
            align="center"
          ></el-table-column>
          <el-table-column label="单价" align="center">
            <template slot-scope="scope">
              <!-- {{ $tool.moneyFormatter(Number(scope.row.price), 2) }} -->
              {{ priceText(scope.row.price)}}
            </template>
          </el-table-column>
          <el-table-column label="总价" align="center">
            <template slot-scope="scope">
              {{ $tool.moneyFormatter(Number(scope.row.orders_sum), 2) }}
            </template>
          </el-table-column>
          <el-table-column prop="headimgurl" label="质检单" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.file_url.length!=0" v-show="scope.row.batch">
                <el-button @click="showViewerFun(scope.row)" size="small ">
                  查看质检单
                </el-button>
                <!-- <el-popover
                  ref="popover0"
                  placement="top-start"
                  width="20"
                  trigger="hover"
                  content="查看质检单"
                >
                  <div
                    slot="reference"
                    class="cursorpointer"
                    @click="showViewerFun(scope.row)"
                  >
                    <i class="el-icon-download"></i>
                  </div>
                </el-popover> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 图片预览组件 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="
        () => {
          showViewer = false
        }
      "
      :url-list="imgList"
    />
  </div>
</template>

<script>
import apiDetail from '@/api/searchDetail.js'
import { Message } from 'element-ui'
export default {
  name: 'OrdersDetail',
  components: {
    'el-image-viewer': () =>
      import('element-ui/packages/image/src/image-viewer'),
  },
  data() {
    return {
      url: '',
      fit: '',
      tableDataDity: [],
      detailData: {},
      showViewer: false,
      imgList: [],
    }
  },
 
  created() {
    this.detailId = this.$route.query.id
    this.gitorderDetail()
  },
  computed: {
    priceText() {
      return (value) => {
        let value_text_list = (value+'').split('.')
        let num = 2
        if(value_text_list[1]){
          num = value_text_list[1].length
        }
        return this.$tool.moneyFormatter(Number(value), num)
      }
    },
  },
  methods: {
    // 链接下载
    choseDownload(url) {
      //首先点击下载事件时创建一个a标签
      let link = document.createElement("a");
      //将a标签隐藏
      link.style.display = "none";
      //给a标签添加下载链接  "域名+接口"
      //safe是一个动态的域名(safe是ip地址，需要自己定义)
      //后面拼接的是接口，替换成你自己的下载接口和需要传递的参数
      link.href = url;
      // 此处给a标签添加一个download属性，属性值就是文件名称否则下载出来的文件是没有属性的
      //下面代码，name会有斜杠，不影响使用
      link.setAttribute("download", name);
      link.target = "_blank";
      //将上面创建的a标签加入到body的尾部
      document.body.appendChild(link);
      //最后执行a标签
      link.click();
      document.body.removeChild(link);
    },
    showViewerFun(row) {
      if(row.is_pdf){
        this.choseDownload(row.file_url[0])
      }else if(row.file_url){
        this.showViewer = true;
        this.imgList = row.file_url;
      }else {
        Message.error('本商品暂无质检单图片')
      }
      // if(file_url){
      //   this.showViewer = true;
      //   this.imgList = file_url;
      // }else{
      //   Message.error('本商品暂无质检单图片')
      // }
    },
    gitorderDetail() {
      apiDetail.orderDetail({ order_id: this.detailId }).then((res) => {
        this.tableDataDity = res.data.shopping_data
        this.detailData = res.data
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.indent {
  height: 2.385417vw;
  background-color: transparent;
  display: flex;
  margin-bottom: 5px;
}
.left {
  width: px(100);
  justify-content: flex-start;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #111111;
}
.conronone {
  flex: 1;
  margin-right: px(20);
  background-color: #fff;
  border-radius: 5px;
  padding: 0 px(8);
}
.particulars {
  display: flex;
}
.particularsleftone {
  margin-top: 5px;
  margin-bottom: 6px;
  color: #999999;
}
.particularslefttwo {
  font-weight: 500;
  color: #333333;
}
.particularscentre {
  flex: 1;
  padding: px(10) 0;
  margin-left: px(15);
  border-right: 1px solid #ebf0f6;
}
.particularscentretwo {
  color: #333333;
  height: px(19);
}
.particularsright {
  flex: 1;
  padding: px(10) 0;
  margin-left: px(15);
}

.particularsrighttwo {
  color: #333333;
}
.Calendar {
  margin: 0 4px;
  width: 190px;
}
.conron {
  overflow: auto;
}
.sheet {
  border-radius: px(5);
  padding: px(5);
}
.particularsleft {
  flex: 1;
  padding: px(10) 0;
  border-right: 1px solid #ebf0f6;
}
</style>
